import { useState, useEffect, useRef } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { onQuestionUpdate } from "./firebase/on-question-updated";
import { addQuestionAnswer } from "./firebase/add-question-answer";
import { LoadingDNA } from ":src/components/loaders";
import { useParams } from "wouter";
//
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { global } from ":global";
import { AQuestion, AAnswer } from "./_model";
import {Title} from ':src/components/title'

const answer: AAnswer = {
  by: undefined as unknown as string,
  value: undefined as unknown as string,
};
const input_refs = {
  by: null as unknown as HTMLInputElement,
  value: null as unknown as HTMLInputElement,
};
const ref_input_value: HTMLInputElement = null as any;
export default function WordCloud(props: { displayOnly?: boolean }) {
  const params = useParams<{ id: string }>();
  const { id } = params;
  const [loading, setLoading] = useState(true);
  const [entity, setEntity] = useState({ id } as AQuestion);

  // on mounting | setup
  useEffect(() => {
    answer.by = undefined as any;
    answer.value = undefined as any;
    try {
      if (!id || typeof id !== "string" || id.length <= 0) {
        throw new Error("unknown id format");
      } else if (parseInt(id) === Number.NaN) {
        throw new Error("id is not a valid number");
      }
      onQuestionUpdate({
        entity,
        onUpdate: onQuestionsChanged,
      });
    } catch (err) {
      console.error(err);
    }

    return () => {
      // cleanup
      if (input_refs.by) {
        input_refs.by = undefined as any;
      }
      if (input_refs.value) {
        input_refs.value = undefined as any;
      }
    };
  }, []);

  function onQuestionsChanged(val: AQuestion) {
    if (!val.question) {
      setLoading(false);
      return;
    }
    unstable_batchedUpdates(() => {
      setEntity(val);
      setLoading(false);
      if (props.displayOnly) {
        global.store.actions.qr_code_bar.setCodeSubRoute(
          `/play/open-question/${id}`
        );
      }
    });
  }

  function handleAnswerChange(ans: AAnswer) {
    answer.by = ans.by ?? answer.by;
    answer.value = ans.value ?? answer.value;
  }
  async function handleSubmit(e: any) {
    e.preventDefault();
    if (!input_refs.by || !input_refs.value) {
      console.error("FATAL: refs haven't been assignd yet!");
      return;
    } else if (!answer.value || answer.value.length <= 0) {
      answer.value = undefined as any;
      return;
    }
    if (!answer.by || answer.by.length <= 0) {
      answer.by = "Anonymous";
    }

    //! warn or reject add if answer.by or answer.value has unacceptable paramters later on.
    addQuestionAnswer({
      entity,
      answer,
    })
      .then(() => {
        answer.by = undefined as any;
        input_refs.by.value = null as any;
        answer.value = undefined as any;
        input_refs.value.value = null as any;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <div className="flex flex-col w-full h-full items-center justify-center overflow-auto">
        {loading ? (
          <LoadingDNA width={"30%"} height={"30%"} />
        ) : !entity.question ? (
          <span>this open question doesn't exist!</span>
        ) : (
          <div className="relative flex flex-col w-full h-full px-2 py-2 overflow-hidden">
          <Title title={entity.question} play={!props.displayOnly}/>
              
            <div className="grid sm:grid-cols-2	gap-5 h-full overflow-x-hidden overflow-y-auto px-2 ">
              {entity.answers?.map((val, index) => {
                return (
                  <div
                    key={`open-question-${id}-${index}`}
                    className="w-full border-solid border-2 border-[#ECECED] p-3"
                  >
                      <span className="text-lg text-normal">{val.value}</span>

                   
                    <p className="flex flex-row ">
                    <i className="i-tdesign:user w-20px h-20px mx-4px" />
                      <span className="capitalize !text-sm">{val.by}</span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {!props.displayOnly && (
        <div className="flex flex-row w-full h-30  items-center justify-center mb-2 children:(w-full) p-4 <md:(flex-col h-60)">
          <InputText
          className="rounded-none w-full m-2 p-4"
            ref={(ref) => (input_refs.by = ref as any)}
            type="text"
            onChange={(e) => handleAnswerChange({ by: e.target.value } as any)}
            placeholder="Enter your name (Optional)"
          />
          <InputText
          className="rounded-none w-full m-2 p-4 "
            type="text"
            ref={(ref) => (input_refs.value = ref as any)}
            onChange={(e) =>
              handleAnswerChange({ value: e.target.value } as any)
            }
            placeholder="Enter your answer"
          />
          <Button label={"Submit"} className="pl-8 pr-8 cursor-pointer rounded-none w-full mx-2 text-center bg-[#0D2937] border-none font-medium py-3 my-2" onClick={handleSubmit} />
        </div>
      )}
    </div>
  );
}
